<template>
  <section id="iq-upcoming-movie">
    <b-container fluid>
      <b-row>
        <b-col sm="12" class="overflow-hidden">
          <div class="iq-main-header d-flex align-items-center justify-content-between">
            <h4 class="main-title">
              <br> 
              <br> 
              <br> 
              
              
              <div style="font-size: 20px;" v-if="$i18n.locale == 'en'">My Videos  </div>
                <div style="font-size: 20px;" v-if="$i18n.locale == 'ku'"> ڤیدیۆکانم </div>
                <div  style="font-size: 20px;" v-if="$i18n.locale == 'ar'"> فيديوهاتي  </div>
            
            
            
            </h4>
          </div>
          <div class="favorites-contens">
            <!-- <Slick class="favorites-slider list-inline  row p-0 mb-0 iq-rtl-direction" ref="dSlick" :option="sliderOption"> -->
            <div class="favorites-slider list-inline  row p-0 mb-0 iq-rtl-direction">
              <li  class="slide-item mb-4 col-12 col-sm-6 col-md-4 col-lg-3" v-for="(item, index) in contents" :key="index">
                <div class="block-images position-relative">
                  <div class="img-box">
                    <img :src="item.content_thumbnail" class="img-fluid" alt="">
                  </div>
                  <div class="block-description">
                    <h6 class="iq-title"><router-link :to="'/movie-detail/'+item.id">{{ item.content_title?.[$i18n.locale] }}
                      </router-link></h6>
                      
                      
                      
                      
                      <div class="buttoncolor"> 
                    <div class="hover-buttons" v-if="$i18n.locale == 'en'">
                      <router-link :to="'/movie-detail/'+item.id" role="button" class="btn btn-hover"><i
                          class="fa fa-play mr-1" aria-hidden="true"></i>
                        Play Now</router-link>
                    </div>
                    
                    
                    <div class="hover-buttons" v-if="$i18n.locale == 'ku'">
                      <router-link :to="'/movie-detail/'+item.id" role="button" class="btn btn-hover"><i
                          class="fa fa-play mr-1" aria-hidden="true"></i>
                          ڤیدیۆکە لێبدە</router-link>
                    </div>
                    
                    <div class="hover-buttons" v-if="$i18n.locale == 'ar'">
                      <router-link :to="'/movie-detail/'+item.id" role="button" class="btn btn-hover"><i
                          class="fa fa-play mr-1" aria-hidden="true"></i>
                          شغل الفيديو</router-link>
                    </div>
                  </div>
                    
                    
                    
                    
                    
                  </div>
                </div>
              </li>
            </div>
            <!-- </Slick> -->
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
export default {
  name: 'PopularShow',
  components: {
  },
  mounted () {
  },
  props: ['contents'],
  data () {
    return {
    }
  },
  methods: {
    next () {
      this.$refs.dSlick.next()
    },
    prev () {
      this.$refs.dSlick.prev()
    }
  }
}
</script>

<style>

.hover-buttons .btn{
  
  background-color: #defe71;
  color:black;
  border-color: #defe71;
}




</style>
